// Generated by Framer (de6f63d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const cycleOrder = ["jTu0q71Rd", "lyl05f1kg"];

const serializationHash = "framer-PDqWC"

const variantClassNames = {jTu0q71Rd: "framer-v-1k9g8zk", lyl05f1kg: "framer-v-rnra3i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Humburger: "jTu0q71Rd", x: "lyl05f1kg"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, njNJdZBy3: tap ?? props.njNJdZBy3, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jTu0q71Rd", vJH2yQXFN: color ?? props.vJH2yQXFN ?? "var(--token-7f5544af-543d-45ce-a4d1-c4fb6f3243f4, rgb(24, 28, 57)) /* {\"name\":\"Neutral/04\"} */"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, njNJdZBy3, vJH2yQXFN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jTu0q71Rd", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap14oqr9g = activeVariantCallback(async (...args) => {
if (njNJdZBy3) {
const res = await njNJdZBy3(...args);
if (res === false) return false;
}
})

const onClick19d1eig = activeVariantCallback(async (...args) => {
setVariant("lyl05f1kg")
})

const onClick1vt7rmm = activeVariantCallback(async (...args) => {
setVariant("jTu0q71Rd")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PDqWC", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1k9g8zk", className)} data-framer-name={"Humburger"} data-highlight layoutDependency={layoutDependency} layoutId={"jTu0q71Rd"} onTap={onTap14oqr9g} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({lyl05f1kg: {"data-framer-name": "x"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1o5pixp-container"} layoutDependency={layoutDependency} layoutId={"ZxxNv0pU4-container"}><Feather color={vJH2yQXFN} height={"100%"} iconSearch={"Home"} iconSelection={"menu"} id={"ZxxNv0pU4"} layoutId={"ZxxNv0pU4"} mirrored={false} onClick={onClick19d1eig} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({lyl05f1kg: {iconSelection: "x", onClick: onClick1vt7rmm}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PDqWC [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PDqWC .framer-vo8bmr { display: block; }", ".framer-PDqWC .framer-1k9g8zk { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 40px; }", ".framer-PDqWC .framer-1o5pixp-container { flex: none; height: 40px; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PDqWC .framer-1k9g8zk { gap: 0px; } .framer-PDqWC .framer-1k9g8zk > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-PDqWC .framer-1k9g8zk > :first-child { margin-left: 0px; } .framer-PDqWC .framer-1k9g8zk > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"lyl05f1kg":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"njNJdZBy3":"tap","vJH2yQXFN":"color"}
 * @framerImmutableVariables true
 */
const Framerqt5pPYoE0: React.ComponentType<Props> = withCSS(Component, css, "framer-PDqWC") as typeof Component;
export default Framerqt5pPYoE0;

Framerqt5pPYoE0.displayName = "Humburger";

Framerqt5pPYoE0.defaultProps = {height: 40, width: 40};

addPropertyControls(Framerqt5pPYoE0, {variant: {options: ["jTu0q71Rd", "lyl05f1kg"], optionTitles: ["Humburger", "x"], title: "Variant", type: ControlType.Enum}, njNJdZBy3: {title: "Tap", type: ControlType.EventHandler}, vJH2yQXFN: {defaultValue: "var(--token-7f5544af-543d-45ce-a4d1-c4fb6f3243f4, rgb(24, 28, 57)) /* {\"name\":\"Neutral/04\"} */", title: "Color", type: ControlType.Color}} as any)

addFonts(Framerqt5pPYoE0, [...FeatherFonts])